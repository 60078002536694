import { Injectable, TemplateRef } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  success(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, {
      classname: "bg-success text-light",
      delay: 2000,
      autohide: true,
      // headertext: "Toast Header",
    });
  }

  error(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, {
      classname: "bg-danger",
      delay: 2000,
      autohide: true,
      // headertext: "Error!!!",
    });
  }

  info(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, {
      classname: "bg-info",
      delay: 2000,
      autohide: true,
      // headertext: "Error!!!",
    });
  }
}
