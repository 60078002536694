<ngb-toast *ngFor="let toast of toastService.toasts" [header]="toast.headertext" [class]="toast.classname"
  [autohide]="toast.autohide" [delay]="toast.delay || 5000" (hide)="toastService.remove(toast)">
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    <div class="message">{{ toast.textOrTpl }}</div>
  </ng-template>
</ngb-toast>
