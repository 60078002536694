import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { deepObjMerge } from 'src/app/libs/utilities';

@Component({
  selector: "simple-line-chart",
  templateUrl: "./simple-line-chart.component.html",
  styleUrls: ["./simple-line-chart.component.scss"],
})
export class SimpleLineChartComponent implements OnInit, OnChanges {
  @Input() traces: { x?: any[] | null; y: any[] }[];
  @Input() customLayout: any;

  data = [];
  layout = {};
  defaultLayout = {
    height: 250,
    autosize: true,
    margin: { l: 20, r: 0, t: 0, b: 0 },
    paper_bgcolor: "transparent",
    plot_bgcolor: "rgba(200,200,200,1)",
    xaxis: {
      color: "white",
      visible: false,
    },
    yaxis: {
      color: "white",
      fixedrange: true,
      visible: true,
    },
  };

  config = {
    displayModeBar: false,
    displaylogo: false,
    locale: "it",
    scrollZoom: true,
    staticPlot: true,
    modeBarButtonsToRemove: [
      "lasso2d",
      // "zoom2d",
      "autoScale2d",
      "toggleSpikelines",
      "hoverClosestCartesian",
      "hoverCompareCartesian",
    ],
  };

  constructor() {}

  ngOnInit(): void {
    this.data = this.createDataConfig();
    this.layout = this.customLayout
      ? deepObjMerge(this.defaultLayout, this.customLayout)
      : this.defaultLayout;
    console.log('Custom layout:', this.customLayout, 'Layout: ',this.layout);
  }

  createDataConfig() {
    const data = [];
    for (let trace of this.traces) {
      const dt = {
        type: "scatter",
        x: trace.x,
        y: trace.y,
        mode: "lines",
        line: {
          color: "rgb(55, 128, 191)",
          width: 1,
        },
      };
      data.push(dt);
    }
    return data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.traces?.currentValue) {
      this.data = this.createDataConfig();
    }
  }
}
