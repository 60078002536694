import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/pages/auth/services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getAuthorizationToken();

    //if token is not present return the original request
    if (!authToken) return next.handle(req);

    const authReq = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + authToken),
    });

    return next.handle(authReq);
  }
}
