import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "simple-bar-chart",
  templateUrl: "./simple-bar-chart.component.html",
  styleUrls: ["./simple-bar-chart.component.scss"],
})
export class SimpleBarChartComponent implements OnInit {
  @Input() label: string;
  @Input() value: number;
  @Input() unitMeasure: string;
  @Input() total: number;
  @Input() barHeight: string = 'calc(100% - 50px)';
  @Input() labelWidth: string;
  @Input() rotateLabel: boolean = false;
  @Input() orientation: "horizontal" | "vertical";

  percent: number;
  topPosition: number;
  rightPosition: number;

  constructor() {}

  ngOnInit(): void {
    this.orientation = this.orientation || "vertical";

    this.updateData();
  }

  isHorizontal() {
    return this.orientation == "horizontal";
  }

  updateData() {
    const percentNotRounded = (this.value / this.total) * 100;
    this.percent = !isNaN(percentNotRounded) ? Math.round(percentNotRounded * 1 * 10) / 10 : 0;

    this.topPosition = !this.isHorizontal() ? this.percent : 100;
    this.rightPosition = this.isHorizontal() ? this.percent : 100;
    this.unitMeasure = this.unitMeasure ? " " + this.unitMeasure : "";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.label?.currentValue ||
      changes.value?.currentValue ||
      changes.unitMeasure?.currentValue ||
      changes.total?.currentValue
    ) {
      this.updateData();
    }
  }
}
