import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";

registerLocaleData(localeIt);

import { PlotlyViaCDNModule } from "angular-plotly.js";
import { HttpClientModule } from "@angular/common/http";
import { httpInterceptorProviders } from "./http-interceptors";
import { LayoutModule } from "./layout/layout.module";

PlotlyViaCDNModule.setPlotlyVersion("2.13.0"); // can be `latest` or any version number (i.e.: '1.40.0')
PlotlyViaCDNModule.setPlotlyBundle(null); // optional: can be null (for full) or 'basic', 'cartesian', 'geo', 'gl3d', 'gl2d', 'mapbox' or 'finance'

const PlotlyModule = PlotlyViaCDNModule;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    PlotlyModule,
    LayoutModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "it-IT" },
    ...httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
