import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tp-unsupported-device',
  templateUrl: './tp-unsupported-device.component.html',
  styleUrls: ['./tp-unsupported-device.component.scss']
})
export class TpUnsupportedDeviceComponent implements OnInit {
  isMobileDevice: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.isMobileDevice = navigator.userAgent.match(/Android/i) != null 
      || navigator.userAgent.match(/webOS/i) != null 
      || navigator.userAgent.match(/iPhone/i) != null 
      || navigator.userAgent.match(/iPod/i) != null;
    // console.log('Is mobile: ', navigator.userAgent.match(/Android/i), " ", navigator.userAgent.match(/webOS/i), " ", navigator.userAgent.match(/iPhone/i), " ", navigator.userAgent.match(/iPod/i));
  }

}
