import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class TpSpinnerService {
  private spinnersCount = 0;
  constructor(private spinner: NgxSpinnerService) {}

  show(name: string = 'full-screen') {
    this.spinnersCount++;
    this.spinner.show(name);
  }

  hide(name: string = 'full-screen') {
    this.spinnersCount--;
    if (this.spinnersCount <= 0) {
      this.spinnersCount = 0;
      this.spinner.hide(name);
    }
  }
}
