export function secondsToTimeTick(allSeconds: number) {
  if(!allSeconds) return "";

  const hours = Math.floor(allSeconds/(60*60));
  const minutes = Math.floor(allSeconds/60)- hours*60;
  const seconds = allSeconds - (hours*60 + minutes)*60;

  let output = hours>0 ? String(hours).padStart(2,'0') + "h " : "";
  output += minutes>0 ? String(minutes).padStart(2,'0') + "m " : "";
  output += String(seconds).padStart(2,'0') + "s";

  return output;
}

export function toBeImplemented() {
  alert("To be implemented :'(");
}

/**
 * Merge two object considering nested object. Source object will be not modified
 * @param o1 Object 1
 * @param o2 Object 2
 * @returns merged object
 */
const merge = (obj1, obj2) => {
  const o1 = Object.assign({}, obj1);
  const o2 = Object.assign({}, obj2);
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(o1)) {
    if (o1[key] instanceof Object) Object.assign(o1[key], merge(o2[key], o1[key]))
  }

  // Join `target` and modified `source`
  Object.assign(o2, o1)
  return o2;
}

export const deepObjMerge = merge;