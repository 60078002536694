import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { InlineSVGModule } from "ng-inline-svg";
import { NavbarComponent } from "./navbar/navbar.component";
import { RouterModule } from "@angular/router";
import { SimpleBarChartComponent } from "./simple-bar-chart/simple-bar-chart.component";
import { SimpleLineChartComponent } from "./simple-line-chart/simple-line-chart.component";
//version and bundle are yet configured in app.module
import { PlotlyViaCDNModule } from "angular-plotly.js";
import { NotificationComponent } from "./notification/notification.component";
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { TpSpinnerComponent } from './tp-spinner/tp-spinner.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { TpUnsupportedDeviceComponent } from './tp-unsupported-device/tp-unsupported-device.component';

@NgModule({
  declarations: [
    NavbarComponent,
    SimpleBarChartComponent,
    SimpleLineChartComponent,
    NotificationComponent,
    TpSpinnerComponent,
    TpUnsupportedDeviceComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    PlotlyViaCDNModule,
    InlineSVGModule.forRoot(),
    NgbToastModule,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    NavbarComponent,
    SimpleBarChartComponent,
    SimpleLineChartComponent,
    NotificationComponent,
    TpSpinnerComponent,
    TpUnsupportedDeviceComponent,
  ],
})
export class LayoutModule {}
