import { Component, TemplateRef } from "@angular/core";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  host: { "[class.ngb-toasts]": "true" },
})
export class NotificationComponent {
  constructor(public toastService: NotificationService) {}

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
