import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize } from "rxjs/operators";
import { TpSpinnerService } from "./tp-spinner.service";

@Injectable()
export class TPSpinnerInterceptor implements HttpInterceptor {
  constructor(private spinner: TpSpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let idTimeout = setTimeout(() => {this.spinner.show();}, 300);
    
    return next.handle(req).pipe(
      finalize(() => {
        clearTimeout(idTimeout);
        this.spinner.hide();
      })
    );
  }
}
