import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  readonly PREFIX = "tp_";
  private storage: any;

  constructor() {
    this.storage = localStorage;
  }

  get(key: string) {
    return localStorage.getItem(this.PREFIX + key);
  }

  set(key: string, value: string) {
    return localStorage.setItem(this.PREFIX + key, value);
  }

  remove(key: string) {
    localStorage.removeItem(this.PREFIX + key);
  }
}
